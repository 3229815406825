import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageheader"
import Container from "../components/container"
import Article from "../components/article"

const ProjectTemplate = ({ data, pageContext }) => {
  const [toggleClass, setToggleClass] = useState("")
  const project = data.markdownRemark
  const { previous, next } = pageContext

  const shareEvent = async (url, title, text) => {
    try {
      await navigator.share({
        url: url,
        title: title,
        text: text,
      })
      console.log("Successfully shared event")
    } catch (err) {
      console.log(`Error: ${err}`)
    }
  }

  const scaleImage = () => {
    setToggleClass(toggleClass === "" ? "scaleImage" : "")
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "contextmenu",
        function(e) {
          e.preventDefault()
        },
        false
      )
    }
  }, [])

  return (
    <Layout theme="dark">
      <SEO
        title={project.frontmatter.title}
        description={project.frontmatter.description || project.excerpt}
        image={project.frontmatter.coverImage.childImageSharp.fluid.src}
      />
      <PageHeader>
        <div className="gobackdiv">
          <a className="goback" onClick={() => navigate(-1)}>
            Go Back
          </a>
        </div>

        <div className={`page-header__split ${toggleClass}`}>
          <div className="page-header__image-container">
            <Img
              className="page-header__image"
              fluid={project.frontmatter.coverImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className="page-header__info">
            <div className="info-content">
              <h1 className="page-header__headline">
                {project.frontmatter.title}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: project.html }}></div>
            </div>
            <div className="info-bar">
              <div className="info-bar-in">
                <div className="info-bar-in__nav">
                  {previous ? (
                    <Link
                      to={previous.fields.slug}
                      className="btn btn--light info-bar-in__btn"
                      rel="previous"
                    >
                      ←
                      {/* <span className="page-header__nav-name">
                        {previous.frontmatter.title}
                      </span> */}
                    </Link>
                  ) : (
                    <div className="btn btn--light btn--disabled info-bar-in__btn">
                      ←
                    </div>
                  )}

                  <div
                    className="btn btn--light info-bar-in__btn info-bar__btn-scale"
                    onClick={() => (window.location.href = "/")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      width="24px"
                      height="24px"
                    >
                      <path
                        fill="white"
                        d="M 24.960938 2.1015625 A 1.0001 1.0001 0 0 0 24.386719 2.3105469 L 1.3867188 20.210938 A 1.0001 1.0001 0 1 0 2.6132812 21.789062 L 4 20.708984 L 4 48 A 1.0001 1.0001 0 0 0 5 49 L 18.832031 49 A 1.0001 1.0001 0 0 0 19.158203 49 L 30.832031 49 A 1.0001 1.0001 0 0 0 31.158203 49 L 45 49 A 1.0001 1.0001 0 0 0 46 48 L 46 20.708984 L 47.386719 21.789062 A 1.0001 1.0001 0 1 0 48.613281 20.210938 L 25.613281 2.3105469 A 1.0001 1.0001 0 0 0 24.960938 2.1015625 z M 25 4.3671875 L 44 19.154297 L 44 47 L 32 47 L 32 29 A 1.0001 1.0001 0 0 0 31 28 L 19 28 A 1.0001 1.0001 0 0 0 18 29 L 18 47 L 6 47 L 6 19.154297 L 25 4.3671875 z M 20 30 L 30 30 L 30 47 L 20 47 L 20 30 z"
                      />
                    </svg>
                  </div>
                  {next ? (
                    <Link
                      to={next.fields.slug}
                      className="btn btn--light info-bar-in__btn"
                      rel="next"
                    >
                      →
                      {/* <span className="page-header__nav-name">
                        {next.frontmatter.title}
                      </span> */}
                    </Link>
                  ) : (
                    <div className="btn btn--light btn--disabled info-bar-in__btn">
                      →
                    </div>
                  )}
                </div>
                <div className="info-bar-in__misc">
                  <button
                    className="btn btn--light info-bar-in__btn"
                    onClick={() =>
                      shareEvent(
                        `https://art.seelay.in/${project.frontmatter.title}`,
                        project.frontmatter.title,
                        `Look at the artwork '${project.frontmatter.title}' by Aman Seelay.`
                      )
                    }
                  >
                    Share
                  </button>
                  <a
                    className="btn btn--light info-bar-in__btn"
                    href={`mailto:aman@seelay.in?subject=${encodeURI(
                      `Purchase_Request: ${project.frontmatter.title}`
                    )}`}
                  >
                    Buy
                  </a>
                  <div
                    id="scale"
                    className="btn btn--light info-bar-in__btn info-bar__btn-scale"
                    onClick={scaleImage}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="M11 6L9 6 9 9 6 9 6 11 9 11 9 14 11 14 11 11 14 11 14 9 11 9z"
                      />
                      <path
                        fill="white"
                        d="M10,2c-4.411,0-8,3.589-8,8s3.589,8,8,8c1.846,0,3.543-0.635,4.897-1.688l4.396,4.396l1.414-1.414l-4.396-4.396 C17.365,13.543,18,11.846,18,10C18,5.589,14.411,2,10,2z M10,16c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S13.309,16,10,16z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageHeader>
      <Container style={{ display: "none" }}>
        <Article>
          <h1 className="article__headline">{project.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: project.html }}></div>
        </Article>
      </Container>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query projectTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        coverImage {
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

ProjectTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}
